import { axiosInstance } from '../methods/axiosConfig'
import { action, computed, makeAutoObservable, reaction } from 'mobx'
import { RootStore } from './Root.store'

export type Transaction = {
  status: string
  attemptData: {
    id: string
    verificationRequestId: string
    verifierId: string
    videoId: string
    registrationAuthorityId: string
  }
  documentData: {
    firstName: string
    lastName: string
    birthDate: string
    expirationDate: string
    nationality: string
    issuer: string
    documentNumber: string
    documentType: string
    documentClassification: string
  }
  processData: {
    evidenceOfIdentity: boolean
    frontSecurityElements: boolean
    photoSimilarity: boolean
    dataReadable: boolean
    frontBackCorespondence: boolean
    livingPerson: boolean
    personInVideoMatchDocument: boolean
    noAdditionalReasonsToReject: boolean
    verificationRequestDate: string
    verificationCompletionDate: string
  }
  metaData: {
    brand: string
    hardware: string
    model: string
    os: string
  }
  securityFeatures: {
    header1: boolean
    header2: boolean
    signature: boolean
    photo: boolean
    texture1: boolean
    texture2: boolean
    badge: boolean
  }
  securityChecks: {
    documentNotExpired: boolean
    notUnderage: boolean
    livenessDetection: boolean
    dataIntegrity: boolean
    documentSidesMatch: boolean
    notReflectiveSurface: boolean
    notBlackAndWhiteCopy: boolean
  }
}

type Capability = 'read' | 'create' | 'readAuditLog' | 'purgePiiData'

export type TenantsCapabilities = {
  [propName: string]: {
    capabilities: Capability[]
  }
}

export class AppStateStore {
  // tenants: string[] = () => tenantObjToArr(this.tenantsCapabilities)
  tenantsCapabilities: TenantsCapabilities = {}
  selectedTenant = ''
  selectedWriteTenant = ''
  loginPageSubtitle = 'Please login using your account'
  currentTransaction: Transaction = {
    status: 'accepted',
    attemptData: {
      id: 'b1104302-0b76-48dd-8981-d1edf15da491',
      verificationRequestId: 'acb33116-2c01-40b4-a830-707dd3ef55cd',
      verifierId: 'f337071e-2697-4d0e-b08c-c32f361c3efa',
      videoId: 'b1104302-0b76-48dd-8981-d1edf15da491',
      registrationAuthorityId: 'b1104302-0b76-48dd-8981-d1edf15da491',
    },
    documentData: {
      firstName: 'Erika',
      lastName: 'Mustermann',
      birthDate: '1984-12-03',
      expirationDate: '1984-12-03',
      nationality: 'DEU',
      issuer: 'DEU',
      documentNumber: 'T123546789',
      documentType: 'TD1',
      documentClassification: 'Ddeu_id_2010EU',
    },
    processData: {
      evidenceOfIdentity: true,
      frontSecurityElements: true,
      photoSimilarity: true,
      dataReadable: true,
      frontBackCorespondence: true,
      livingPerson: true,
      personInVideoMatchDocument: true,
      noAdditionalReasonsToReject: true,
      verificationRequestDate: '12.04.2022 at 12.14.56',
      verificationCompletionDate: '12.04.2022 at 12.16.52',
    },
    metaData: {
      brand: 'Xiaomi',
      hardware: 'qcom',
      model: 'Mi Note 10 Lite',
      os: '11-REL',
    },
    securityFeatures: {
      header1: true,
      header2: true,
      signature: true,
      photo: true,
      texture1: true,
      texture2: true,
      badge: true,
    },
    securityChecks: {
      documentNotExpired: true,
      notUnderage: true,
      livenessDetection: true,
      dataIntegrity: true,
      documentSidesMatch: true,
      notReflectiveSurface: true,
      notBlackAndWhiteCopy: true,
    },
  }

  isUserWithAnyWriteRights = true

  private rootStore: RootStore

  constructor(rootStore) {
    makeAutoObservable(this)
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    this.rootStore = rootStore

    reaction(
      () => this.selectedTenant,
      () => {
        this.rootStore.Filters.fetchTags()
          // eslint-disable-next-line no-console
          .catch((error) => console.log(error))
      }
    )
  }

  @action.bound setLoginPageSubtitle(subtitle: string): void {
    this.loginPageSubtitle = subtitle
  }

  @action.bound setTenantsCapabilities(tenants: TenantsCapabilities): void {
    this.tenantsCapabilities = tenants
  }

  @action.bound setSelectedTenant(tenant: string): void {
    this.selectedTenant = tenant
    localStorage.setItem('selectedTenant', tenant)
  }

  @action.bound setSelectedWriteTenant(tenant: string): void {
    this.selectedWriteTenant = tenant
  }

  @action.bound setCurrentTransaction(transaction: Transaction): void {
    this.currentTransaction = transaction
  }

  @action.bound async fetchTenants() {
    const localSelectedTenant = localStorage.getItem('selectedTenant')

    if (localSelectedTenant?.length && localSelectedTenant !== 'null' && localSelectedTenant !== 'undefined') {
      this.setSelectedTenant(localSelectedTenant)
    }

    const res = await axiosInstance.get<TenantsCapabilities>(`/tenants/capabilities`, {
      withCredentials: true,
    })

    this.setTenantsCapabilities(res.data)

    // Perms check for create transaction
    const createArr: string[] = []

    for (const propName in res.data) {
      if (res.data[propName].capabilities.includes('create')) {
        createArr.push(propName)
      }
    }

    if (!createArr.length) {
      this.setIsUserWithAnyWriteRights(false)
    }

    ///////////////////////////////////////

    if (!this.tenants.find((resTenant) => resTenant === localSelectedTenant)) {
      this.setSelectedTenant(undefined)
    }
  }

  @action.bound setIsUserWithAnyWriteRights(status: boolean) {
    this.isUserWithAnyWriteRights = status
  }

  @computed get tenants(): string[] {
    return Object.keys(this.tenantsCapabilities)
  }

  @computed get tenantsWithWriteRights(): string[] {
    const createArr: string[] = []
    for (const propName in this.tenantsCapabilities) {
      if (this.tenantsCapabilities[propName].capabilities.includes('create')) {
        createArr.push(propName)
      }
    }

    return createArr
  }

  @computed get tenantsWithAuditLogRights(): string[] {
    const auditLogArr: string[] = []
    for (const propName in this.tenantsCapabilities) {
      if (this.tenantsCapabilities[propName].capabilities.includes('readAuditLog')) {
        auditLogArr.push(propName)
      }
    }

    return auditLogArr
  }

  @computed get tenantsWithPurgePiiDataRights(): string[] {
    const purgePiiDataArr: string[] = []
    for (const propName in this.tenantsCapabilities) {
      if (this.tenantsCapabilities[propName].capabilities.includes('purgePiiData')) {
        purgePiiDataArr.push(propName)
      }
    }

    return purgePiiDataArr
  }
}
