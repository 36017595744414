import { format } from 'date-fns'
import { axiosInstance } from '../../../methods/axiosConfig'
import { pdfExample } from '../PDFsBase64.constant'
import { toast } from 'react-toastify'

export interface JsonErrorLine {
  from: number
  to: number
  message: string
  severity: 'error' | 'warning'
}

export type JsonError = JsonErrorLine[]

export interface TransactionPostRequest {
  configurationId: string
  preferredLanguage?: string
  redirectUrl: string
  user?: {
    firstName?: string
    lastName?: string
    phoneNumber?: string
    email?: string
    address?: {
      city?: string
      postalCode?: string
      street?: string
    }
    dateOfBirth?: string
    nationalities?: string[]
    bankInformation?: {
      accountHolder?: string
      iban?: string
    }
  }
  pdfDocument?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any
}

export interface TransactionPostResponse {
  transactionId: string
  proceedToken: string
  proceedUrl: string
}

export interface TransactionResponseParsed {
  transactionId: string
  proceedToken: string
  proceedUrl: string
  status: string
  date: string
  apiUrl: string
  errorMessage?: string
}

export const DEFAULT_REDIRECT_URL = 'https://signicat.com'

export const CONFIGURATION_LIST = [
  { label: 'Micropayment + Signing + VideoId', value: 'signicat.with.micropayment' },
  { label: 'Signing + VideoId', value: 'signicat.without.micropayment' },
  { label: 'Idin + VideoId', value: 'signicat.eid.idin.videoId' },
  // { label: 'Signing + VideoId + Tink + Address Verification (Ikano)', value: 'ikano.de' },
  // { label: 'Signing + VideoId + Klarna (Jitpay)', value: 'jitpay.eu' },
  // { label: 'Klarna', value: 'jitpay.eu.micropayment' },
  // { label: 'Micropayment + Signing + VideoID (BMW)', value: 'bmw.de' },
]

export const CONFIGURATION_LIST_V3 = [
  { label: 'Address Verification', value: 'demo.addressVerification' },
  { label: 'Signing + VideoId + Address Verification', value: 'demo.addressVerification.2' },
]

export const LANGUAGES_LIST = [
  { label: 'en', value: 'en' },
  { label: 'de', value: 'de' },
  { label: 'fr', value: 'fr' },
  { label: 'it', value: 'it' },
  { label: 'pl', value: 'pl' },
]

export const THEME_LIST = [
  { label: 'signicatTheme', value: 'signicatTheme' },
  { label: 'ikanoTheme', value: 'ikanoTheme' },
  { label: 'bwmTheme', value: 'bmwTheme' },
  { label: 'miniTheme', value: 'miniTheme' },
  { label: 'amexTheme', value: 'amexTheme' },
]

export const V3_OPTIONS_LIST = [
  { value: 'off', label: 'off' },
  { value: 'on', label: 'on' },
]

export const PDF_NAME_LIST = [{ label: 'pdfExample', value: 'pdfExample' }]

export const isValidUrl = (url: string) => {
  try {
    const newUrl = new URL(url)
    return newUrl.protocol === 'http:' || newUrl.protocol === 'https:'
  } catch (err) {
    return false
  }
}

export const pdfSelector = (pdfName: string) => {
  switch (pdfName) {
    case 'pdfExample':
      return pdfExample
    default:
      return ''
  }
}

export const convertToBase64 = (file: File): Promise<string> => {
  const reader = new FileReader()
  reader.readAsDataURL(file)

  return new Promise((resolve, reject) => {
    reader.onload = () => resolve(reader.result as string)
    reader.onerror = (error) => reject(error)
  })
}

export const postTransaction = async (
  tenantId: string,
  transaction: TransactionPostRequest,
  pdfDocument?: string,
  isV3?: boolean
): Promise<TransactionResponseParsed> => {
  try {
    const response = await axiosInstance.post<TransactionPostResponse>(
      `/transactions`,
      {
        ...transaction,
        ...(pdfDocument.length > 0 && { pdfDocument }),
      },
      {
        withCredentials: true,
        params: {
          tenantId,
          isV3,
        },
      }
    )

    let status = response.status.toString()
    status = status.slice(0, 1) === '2' ? `${response.status} OK` : `${response.status} FAIL`

    const now = new Date()

    return {
      ...response.data,
      status,
      date: format(now, 'HH:mm, dd.MM.yyyy'),
      apiUrl: `${axiosInstance.defaults.baseURL}/transactions/${response.data.transactionId}?tenantId=${tenantId}${
        isV3 ? '&isV3=true' : ''
      }`,
    }
  } catch (error) {
    interface CustomError {
      response: {
        status: number
        statusText: string
        data: {
          message: string
        }
      }
    }

    const errorData = error as CustomError

    toast('Unable to create transaction: ' + errorData?.response?.data?.message, { type: 'error' })

    const now = new Date()
    return {
      transactionId: undefined,
      proceedToken: undefined,
      proceedUrl: undefined,
      status: errorData?.response?.status?.toString() + ' FAIL',
      date: format(now, 'HH:mm, dd.MM.yyyy'),
      apiUrl: undefined,
      errorMessage: errorData?.response?.data?.message,
    }
  }
}
